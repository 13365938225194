<template>
  <div class="live-meeting">

    <div class="d-flex mb-3" style="justify-content:space-between;">
      <el-card class="block" shadow="never">
        <div><b>今日课程数</b></div>
        <div class="mt-1"><b class="big-number text-info">{{ number.courses }}</b></div>
      </el-card>
      <el-card class="block" shadow="never">
        <div><b>今日上课人次</b></div>
        <div class="mt-1"><b class="big-number text-info">{{ number.students }}</b></div>
      </el-card>
      <el-card class="block" shadow="never">
        <div><b>正在进行课程</b></div>
        <div class="mt-1"><b class="big-number text-info">{{ number.live_courses }}
          <small class="text-muted hidden">/{{ number.total_courses }}</small></b>
        </div>
      </el-card>
      <el-card class="block" shadow="never">
        <div><b>当前在线人数</b></div>
        <div class="mt-1"><b class="big-number text-info">{{ number.live_students }}<small
            class="text-muted hidden">/{{ number.total_students }}</small></b></div>
      </el-card>
      <el-card class="block expend" shadow="never" :body-style="{ padding: '0px' }">
        <div ref="my_chart" class="w-100" style="height: 100px;">

        </div>
      </el-card>
    </div>

    <el-card :body-style="{ padding: '0px' }" shadow="never">
      <div ref="excel_table">
        <el-table size="medium" stripe
                  :data="meetings.filter(data => !search || data.topic.toLowerCase().includes(search.toLowerCase()))"
                  style="width: 100%; margin-top: -1px;background-color: #f8f8f8">
          <el-table-column sortable
                           width="150"
                           label='会议号码'>
            <template slot-scope="scope">
              <el-button plain type="default" size="small" @click="askJoinTheMeeting( scope.row.id )">
                {{ scope.row.code | split3 }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column width="80"
                           label='查看'>
            <template slot-scope="scope">
              <el-button plain type="link" size="mini" icon="el-icon-search"
                         @click="reviewParticipants( scope.row.id )"></el-button>
            </template>
          </el-table-column>
          <el-table-column sortable width="220"
                           label='时间'>
            <template slot-scope="scope">
              <span>{{ scope.row.start_time }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="teacher" width="220"
                           label='教师'>
          </el-table-column>
          <el-table-column prop="topic">
            <div slot="header" class="d-flex" slot-scope="scope">
              <div style="flex: 0 0 3em;line-height: 30px">主题<span style="display: none">{{ scope ? '' : '' }}</span>
              </div>
              <el-input style="flex:1 1 5em"
                        v-model="search"
                        size="mini"
                        placeholder="输入关键字搜索"/>
            </div>
          </el-table-column>
          <el-table-column width="80" prop="participants"
                           label='人数' class-name="text-center">
          </el-table-column>
        </el-table>
      </div>

    </el-card>

    <el-dialog :visible.sync="view_mode" width="99%">
      <div slot="title" class="text-primary">
        {{ single.course_name }} | {{ single.teacher_name }}
      </div>
      <div>
        <el-table border size="medium"
                  :data="participants"
                  style="width: 100%">
          <el-table-column sortable
                           width="220"
                           label='用户id'>
            <template slot-scope="scope">
              <span class="text-info">{{ scope.row.userid }} </span>
            </template>
          </el-table-column>
          <el-table-column width="" prop="name"
                           label='用户姓名' class-name="text-center">
          </el-table-column>
          <el-table-column width="180" prop="enter"
                           label='加入时间' class-name="text-center">
          </el-table-column>
          <el-table-column width="180" prop="leave"
                           label='离开时间' class-name="text-center">
          </el-table-column>
          <el-table-column width="" prop="phone"
                           label='电话编码' class-name="text-center">
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="" @click="view_mode = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PushMixin from "../common/mixins/echo-mixin"
import echarts from "echarts"

export default {
  name: "admin-report",
  mixins: [PushMixin],
  data() {
    return {
      meetings: [],
      live: {total_records: 0},
      total_user: 0,
      view_mode: false,
      single: {},
      participants: [],
      chart: null,
      number: {},
      serials: [],
      headers: [],
      search: '',
    }
  },
  filters: {
    split3(value) {
      if (!value) return '';
      const v = value.toString();
      return v.replace(/\d(?=(?:\d{3})+\b)/g, '$&-');
    },
    formatTime(value) {
      if (!value) return '';
      return new Date(value).Format('yyyy-MM-dd hh:mm');
    },
    onlyReason(value) {
      if (!value) return '';
      const ret = value.split('原因：');
      if (ret.length > 1) return ret[1];
      if (ret[0].trim() == '') return '';
      return ret[0];
    }
  },
  computed: {
    ...mapState(['process', 'preset']),
  },
  methods: {
    reviewParticipants(id) {
      const vm = this;
      vm.$api('Admin.Report.LiveMeetingParticipants', {id: id}, 1).then((res) => {
        vm.participants = res.data.data.participants;
        vm.single = res.data.data;
        vm.view_mode = true;
      });
    },
    askJoinTheMeeting(id) {
      const vm = this;
      this.meetings.forEach((meeting) => {
        if (meeting['id'] == id) {
          vm.$affirm('要加入此会议(' + meeting.code + ')吗? 会议密码为:' + meeting.password).then(() => {
            window.open(meeting.start_url, "_blank");
          });
        }
      });
    },
    getStatus() {
      const vm = this;
      this.$api('Admin.Report.LiveMeetings', {}, 1).then(res => {
        this.live = res.data.data.live;
        this.meetings = res.data.data.live.meetings;
        this.total_user = 0;
        this.meetings.forEach((item) => {
          vm.total_user += parseInt(item.participants)
        });
        this.number = res.data.data.number;

        this.serials = res.data.data.serials;
        this.headers = res.data.data.headers;
        this.calculateChart();

      });
    },
    calculateChart() {
      let keys = [];
      let meetings = [];
      let participants = [];
      let real_students = [];
      for (let i = 0; i < this.headers.length; i++) {
        const k = this.headers[i];
        keys.push(k);
        if (this.serials[k]) {
          meetings.push(parseInt(this.serials[k].meetings));
          participants.push(parseInt(this.serials[k].participants));
          real_students.push(parseInt(this.serials[k].real_students));
        } else {
          meetings.push(0);
          participants.push(0);
          real_students.push(0);
        }
      }
      let option = {
        color: ['#d9e3e3', '#98cde0', '#61a0a8'],
        title: {
          show: false
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow',        // 默认为直线，可选为：'line' | 'shadow'
            snap: true,
          },
          textStyle: {
            fontSize: 10
          },
          position: (point, params, dom, rect, size) => {
            if (point[0] < size.viewSize[0] - 80) {
              return [point[0] + 5, 0];
            } else {
              return [point[0] - 40, 0];
            }
          }

        },
        legend: {show: false},
        grid: {
          left: 60,
          right: 10,
          bottom: 25,
          top: 15,
        },
        xAxis: {
          type: 'category',
          data: keys,
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            type: 'bar',
            data: meetings
          },
          {
            type: 'bar',
            data: participants
          }]
      };

      this.chart.setOption(option);
      this.chart.resize();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = 0;
    }
    next();
  },
  mounted() {
    this.chart = echarts.init(this.$refs.my_chart);
    this.calculateChart();
  },
  created() {
    const vm = this;
    this.timer = setInterval(function () {
      vm.getStatus();
    }, 5 * 60000);
    vm.getStatus();
  },
}
</script>

<style scoped lang="scss">

.live-meeting {
  .big-number {
    font-size: 1.5em;
  }

  .hidden {
    display: none;
  }

  .block {
    flex: 0 0 150px;
    text-align: center;
    height: 100px;
    margin: 0 5px;
    border-radius: 8px;
    background-color: #fafafa
  }

  .block:first-child {
    margin-left: 0 !important;
  }

  .block:last-child {
    margin-right: 0 !important;
  }

  .block.expend {
    flex: 1 1 350px;
    text-align: center;
    height: 100px;
    margin: 0 1.2em
  }
}


</style>
