<template>
    <div class="">
        <el-divider></el-divider>
        <el-row type="" style="height: 400px;">
            <el-col :lg="24" :md="24">
                <div ref="echarts_courses"  style="border-color:#eee;height: 400px;"></div>
            </el-col>
            <el-col :lg="24" :md="24">
                <div ref="echarts_visitors"  style="border-color:#eee;height: 400px;"></div>
            </el-col>
        </el-row>

    </div>
</template>

<script>
import {mapState} from "vuex"
import echarts from "echarts"

export default {
    name: "report-reports",
    data() {
        return {
            index: 'first',
            charts: {
                visitors: null,
                courses: null,
            },
            model: '',
            reports:['课表','课程数据明细表','课程信息汇总表','课程数据汇总表','课程分类统计表'],
        }
    },
    created() {
        this.model = 'Admin.Report';
        this.date = new Date().Format('yyyy-MM-dd');
        this.getCharts();
    },
    mounted() {

    },
    computed: {
        ...mapState(['process', 'preset', 'user']),
    },
    methods: {
        getCharts() {
            this.$api('Admin.Chart.Charts', {}, true).then(res => {
                let data = res.data.data.courses;
                let axis = data['names'];
                let numbers = data['numbers'];
                const option1 = {
                    title: {text: '各区课程表'},
                    tooltip: {},
                    xAxis: {
                        data: axis,
                        axisLabel: {
                            interval: 0,
                            rotate: 40
                        }
                    },
                    yAxis: {},
                    toolbox: {
                        show: true,
                        feature: {
                            magicType: {show: true, type: ['line', 'bar']},
                            saveAsImage: {show: true}
                        }
                    },
                    series: [{
                        name: '各区课程表',
                        type: 'bar',
                        data: numbers,
                        label: {
                            normal: {
                                show: true,
                                position: 'top',
                                formatter: function (params) {
                                    return (params.value > 0 ? params.value : '');
                                }
                            },
                        },
                    }]
                };

                this.charts.courses = echarts.init(this.$refs['echarts_courses']);
                this.charts.courses.setOption(option1);

                data = res.data.data.visitors;
                axis = data['names'];
                numbers = data['numbers'];
                const option2 = {
                    title: {text: '各区用户表'},
                    tooltip: {},
                    xAxis: {
                        data: axis,
                        axisLabel: {
                            interval: 0,
                            rotate: 40
                        }
                    },
                    yAxis: {},
                    toolbox: {
                        show: true,
                        feature: {
                            magicType: {show: true, type: ['line', 'bar']},
                            saveAsImage: {show: true}
                        }
                    },
                    series: [{
                        name: '各区课程表',
                        type: 'bar',
                        data: numbers,
                        label: {
                            normal: {
                                show: true,
                                position: 'top',
                                formatter: function (params) {
                                    return (params.value > 0 ? params.value : '');
                                }
                            },
                        },
                    }]
                };

                this.charts.visitors = echarts.init(this.$refs['echarts_visitors']);
                this.charts.visitors.setOption(option2);
            });
        },
    }
}
</script>

<style scoped lang="scss">

.import-syllabus {
    .content-wrapper {
        display: flex;
        flex-wrap: wrap;

        .action-card {
            width: 250px;
            margin: 0 20px 20px;

            .card-body {
                min-height: 200px;
            }
        }

        ul {
            list-style: none;
            padding: 0;
        }

        .process-card {
            flex: 1 1 250px;
            min-height: 353px;
            margin: 0 20px 20px;

            .header {
                height: 86.3px;
                text-align: center;
                line-height: 86.3px;
                border-bottom: 1px solid #e6e6e6;
                font-size: 20px;
            }

            .card-body {
                height: 200px;
            }

        }
    }
}
</style>
