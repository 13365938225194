<template>
    <div class="admin-report">
        <el-row class="w-100 h-100 px-1">
            <h5 style="font-size: 18px; ">数据统计表 <small class="ml-5 text-info">请选择学期: </small>
                <el-select v-model="semester_index" size="small"  class="ml-3" style="width:350px;" @change="handleChangeSemester">
                    <el-option v-for="(x,id) in semester_options" :key="id" :value="id" :label="x.name+' ('+x.start_date+' - '+x.end_date+')'">
                        <span class="d-inline-block" style="width:200px;">{{ x.name }}</span>
                        <span class="text-success">{{ x.start_date }} - {{ x.end_date }}</span>
                    </el-option>
                </el-select>
            </h5>
            <div class="line"></div>
            <table class="report-table">
                <tbody>
                <tr v-for="(x,i) in reports" :key="x.id" style="border-bottom: 1px solid #ddd">
                    <td>{{i+1}}</td>
                    <td class="report-name">{{ x.name }}</td>
                    <td class="report-option" >
                        <el-select v-if="x.options" v-model="x.select" size="small" filterable>
                            <el-option v-for="y in semesterFilter(x.options)" :key="y.id" :value="y.id" :label="y.course_number+' | '+y.course_name"></el-option>
                        </el-select>
                    </td>
                    <td class="report-link">
                        <template v-if="x.url">
                            <el-link :href="x.url" target="_blank" icon="el-icon-download"> 下载报表 |
                            <small>(生成时间:{{ x.time }})</small></el-link>
                        </template>
                    </td>
                    <td class="report-status">
                        <el-button type="text" :icon="x.busy==1?'el-icon-loading':'el-icon-position'" @click="makeReport(x.id)">
                        {{x.busy == 1 ? '重新生成' : '生成' }}
                        </el-button>
                    </td>
                </tr>
                </tbody>
            </table>
        </el-row>
    </div>
</template>

<script>
import {mapState} from "vuex";
import Echo from "../common/mixins/echo-mixin"
import SemesterMixin from "@/views/mixins/semester.mixin"

export default {
    name: "report-reports",
    mixins:[Echo,SemesterMixin],
    data() {
        return {
            model: 'Admin.Report',
            reports: [],
            period:[],
        }
    },
    created() {
        this.loadSemesterList();
    },
    mounted() {
        this.getReports();
        this.initPusher(this.handlePusherData);
    },
    computed: {
        ...mapState(['process', 'preset', 'user']),
    },
    methods: {
        semesterFilter(array) {
            if (this.semester_index === 0) return array;
            const semester = this.semester_options[this.semester_index];
            return array.filter(i=>{
                return !i.time_from || (i.time_from === "") || (i.time_from.substr(0, 10) <= semester.end_date && i.time_to.substr(0,10)>=semester.start_date);
            })
        },
        getReports() {
            this.$api(this.model + '.List', {}, true).then(res => {
                this.mergeReport( res.data.data );
            });
        },
        findReport(id) {
            for (let i = 0; i < this.reports.length; i++) {
                if (this.reports[i].id === id) return this.reports[i];
            }
            return null;
        },
        makeReport(id) {
            const report = this.findReport(id);
            if (!report) {
                return this.$notice.error('没有找到这个报告!');
            }
            const param =  this.addChannel({id: id,period:this.period,select:report.select,semester:this.semester_index});
            this.$api(this.model + '.Report', param, true).then(res => {
                this.mergeReport( res.data.data );
            });
            this.$messageBox.success('报表已经在生成，请等待!');
        },
        mergeReport(data) {
            if (data) {
                for (let i=0; i < data.length; i++) {
                    if (data[i].select) {
                        if (this.reports.length > i) {
                            data[i].select = this.reports[i].select || data[i].select
                        }
                    }
                }
            }
            this.reports = data;
        },
        handlePusherData(data){
            if (data && data.message ) {
                this.getReports();
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        this.disconnectPusher();
        next();
    },
}
</script>

<style scoped lang="scss">
.admin-report {
    .report-table {
        td{ padding: 0 25px;}
    }
}
</style>
